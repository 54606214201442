import { hotelInterface } from "../../interfaces/hotels.interface"
export let HOTELS: Array<hotelInterface> = [
  {
    id: 1,
    name: "Quinta San José",
    ubicacion: "Distrito Capital",
    urlMaps: "https://www.google.com/maps/place/CableHOGAR/@10.4860394,-66.9438706,17z/data=!4m8!1m2!2m1!1sUrb.+Las+Fuentes+de+El+Para%C3%ADso.+2da+Av.+entre+calle+2+y+3.+Quinta+San+Jos%C3%A9.+El+Para%C3%ADso+-+Caracas!3m4!1s0x8c2a5f0ee5823411:0x579d8dfa22a71e03!8m2!3d10.4859999!4d-66.9431158?hl=es",
    location: { lat: 10.48601, lon: -66.94312 },
    address: ["📌 Urbanización Las Fuentes de El Paraíso, 2da Av.Entre calle 2 y 3."],
    description: ["Lunes a Sábado 8:00 a.m a 5:00 p.m", "Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/Screenshot_12.png",
    images: ["assets/img/Screenshot_12.png"]
  },
  {
    id: 1,
    name: "Quinta San José",
    ubicacion: "Distrito Capital",
    urlMaps: "https://www.google.com/maps/place/C.+C.+Galer%C3%ADas+Para%C3%ADso/@10.4749527,-66.948368,1121m/data=!3m2!1e3!4b1!4m6!3m5!1s0x8c2a5f740da1d489:0xb630fcac13751860!8m2!3d10.4749527!4d-66.9457931!16s%2Fg%2F1hdzqg5rt?entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D",
    location: { lat: 10.48601, lon: -66.94312 },
    address: ["📌 C. C. Galerías Paraíso , Nivel Paraíso local PA05"],
    description: ["Lunes a Sábado 8:00 a.m a 5:00 p.m", "Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/Screenshot_12.png",
    images: ["assets/img/Screenshot_12.png"]
  },
  {
    id: 1,
    name: "Propatria",
    ubicacion: "Distrito Capital",
    urlMaps: "https://www.google.co.ve/maps/place/Centro+Comercial+Propatria/@10.503716,-66.9529822,20z/data=!4m14!1m7!3m6!1s0x8c2a5feb30d6a195:0x597712611e7b6f72!2sCentro+Comercial+Propatria!8m2!3d10.503743!4d-66.9529299!16s%2Fg%2F11lgwsrcf0!3m5!1s0x8c2a5feb30d6a195:0x597712611e7b6f72!8m2!3d10.503743!4d-66.9529299!16s%2Fg%2F11lgwsrcf0?hl=es-419&entry=ttu",
    location: { lat: 10.48601, lon: -66.94312 },
    address: ["📌 Centro Comercial Propatria Nivel Patio, AV. Simón Bolívar # 1A-07."],
    description: ["Lunes a Sábado 8:00 a.m a 5:00 p.m", "Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/Screenshot_12.png",
    images: ["assets/img/Screenshot_12.png"]
  },
  {
    id: 2,
    name: "Sucursales",
    ubicacion: "Carabobo",
    urlMaps: "https://www.google.com/maps/place/Metr%C3%B3polis+Shopping+Center/@10.1834052,-67.9758901,21z/data=!4m9!1m2!2m1!1sMetropolis:+Shopping,+Nivel+Agua,+Ancla+2,+Subiendo+a+la+Rampla+San+Diego+Carabobo.+Venezuela!3m5!1s0x8e8067ed13da22c7:0xf68543a4eb33e1fa!8m2!3d10.1834313!4d-67.975941!15sCl1NZXRyb3BvbGlzOiBTaG9wcGluZywgTml2ZWwgQWd1YSwgQW5jbGEgMiwgU3ViaWVuZG8gYSBsYSBSYW1wbGEgU2FuIERpZWdvIENhcmFib2JvLiBWZW5lenVlbGFaWiJYbWV0cm9wb2xpcyBzaG9wcGluZyBuaXZlbCBhZ3VhIGFuY2xhIDIgc3ViaWVuZG8gYSBsYSByYW1wbGEgc2FuIGRpZWdvIGNhcmFib2JvIHZlbmV6dWVsYZIBE21lbnNfY2xvdGhpbmdfc3RvcmWaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUkhNamw1UnpSUlJSQUI",
    location: { lat: 10.183448443304666, lon: -67.97593772558157 },
    address: [
      "📌 C.C.Metrópolis Nivel Agua Ancla 2 GA M2-104.",
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 10:00 a.m a 12:00 p.m y 1:00 p.m a 4:45 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],
    thumb: "assets/img/Screenshot_1.png",
    images: ["assets/img/Screenshot_1.png"]
  },
  {
    id: 2,
    name: "Sucursales",
    ubicacion: "Carabobo",
    urlMaps: "https://goo.gl/maps/X6yvkvVzsHQK95ou9",
    location: { lat: 10.233760283240056, lon: -67.99949479333638 },
    address: [
      "📌 C.C Vía Veneto Av. 168 Salvador Feo La Cruz Este - Oeste, Naguanagua 2005. Local F-5 Nivel Fiorentina.",
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 1:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],
    thumb: "assets/img/Screenshot_1.png",
    images: ["assets/img/Screenshot_1.png"]
  },
  {
    id: 2,
    name: "Sucursales",
    ubicacion: "Carabobo",
    urlMaps: "https://goo.gl/maps/te3bHfMRrDqDnEHs6",
    location: { lat: 10.164629678544905, lon: -67.96029877335876 },
    address: [
      "📌 C.C. Paseo las Industrias Nivel Mezzanina, Local, L3-119 Valencia, Carabobo.",
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 1:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],
    thumb: "assets/img/Screenshot_1.png",
    images: ["assets/img/Screenshot_1.png"]
  },
  {
    id: 2,
    name: "Sucursales",
    ubicacion: "Carabobo",
    urlMaps: "https://goo.gl/maps/UfqXLhHjDN2H3SZG7",
    location: { lat: 10.464886101350785, lon: -68.00780723131804},
    address: [
      "📌 C.C. Plaza City Piso 2 Local L 201, Puerto Cabello. ",
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 1:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],
    thumb: "assets/img/Screenshot_1.png",
    images: ["assets/img/Screenshot_1.png"]
  },
  {
    id: 2,
    name: "Sucursales",
    ubicacion: "Carabobo",
    urlMaps: "https://goo.gl/maps/cxR9pbjNEnNNJk3HA",
    location: { lat: 10.21347989183788, lon: -67.89643211376405},
    address: [
      "📌 C.C. Alianza Mall, Piso 1, Local E13, Guacara.",
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 1:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],
    thumb: "assets/img/Screenshot_1.png",
    images: ["assets/img/Screenshot_1.png"]
  },
  {
    id: 2,
    name: "Sucursales",
    ubicacion: "Carabobo",
    urlMaps: "https://www.google.com/maps/place/FIBEX+TELECOM+BEJUMA/@10.1732129,-68.2609313,17z/data=!4m14!1m7!3m6!1s0x8e808d8bb1dc2859:0x7116c07dd2269331!2sFIBEX+TELECOM+BEJUMA!8m2!3d10.1744748!4d-68.2608562!16s%2Fg%2F11sswhs2h0!3m5!1s0x8e808d8bb1dc2859:0x7116c07dd2269331!8m2!3d10.1744748!4d-68.2608562!16s%2Fg%2F11sswhs2h0?entry=ttu",
    location: { lat: 10.1732129, lon: -68.2609313},
    address: [
      "📌 Av. Carabobo C/C Urdaneta CC Colonial Nivel P-3 Of B Sector Centro Bejuma.",
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 1:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],
    thumb: "assets/img/Screenshot_1.png",
    images: ["assets/img/Screenshot_1.png"]
  },
  {
    id: 2,
    name: "Sucursales",
    ubicacion: "Carabobo",
    urlMaps: "https://www.google.com/maps/place/Fibex+Guigue/@10.0832305,-67.7810804,18z/data=!4m10!1m2!2m1!1sfibex!3m6!1s0x8e806db024fc965b:0xa3bd3dde8136bc1b!8m2!3d10.0838784!4d-67.778754!15sCgVmaWJleJIBEGNvcnBvcmF0ZV9vZmZpY2XgAQA!16s%2Fg%2F11tp18982d?entry=ttu",
    location: { lat: 10.0832305, lon: -67.7810804},
    address: [
      "📌 Av. Bolívar y Miranda CL 3 de diciembre # local, Guigue.",
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 1:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],
    thumb: "assets/img/Screenshot_1.png",
    images: ["assets/img/Screenshot_1.png"]
  },
  {
    id: 2,
    name: "Sucursales",
    ubicacion: "Carabobo",
    urlMaps: "https://www.google.co.ve/maps/place/Calle+Ayacucho,+Mor%C3%B3n+2051,+Carabobo/@10.4896642,-68.1996047,19z/data=!4m15!1m8!3m7!1s0x8e80fee702e81d71:0xc421fb88fe9af2a9!2sCalle+Ayacucho,+Mor%C3%B3n+2051,+Carabobo!3b1!8m2!3d10.4897585!4d-68.1992252!16s%2Fg%2F1tcym2j6!3m5!1s0x8e80fee702e81d71:0xc421fb88fe9af2a9!8m2!3d10.4897585!4d-68.1992252!16s%2Fg%2F1tcym2j6?hl=es-419&entry=ttu",
    location: { lat: 10.4896642, lon: -68.1996047 },
    address: [
      "📌 Calle Plaza C/C Calle Ayacucho. Local 2. Morón.",
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 1:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],
    thumb: "assets/img/Screenshot_1.png",
    images: ["assets/img/Screenshot_1.png"]
  },
  {
    id: 2,
    name: "Sucursales",
    ubicacion: "Carabobo",
    urlMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d490.76779785399367!2d-67.89032939933782!3d10.250098505106093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8043bcf50e6a1f%3A0x73d1ff63fc040cc!2sCentro%20Comercial%20Piedra%20Pintada!5e0!3m2!1ses!2sve!4v1685115805264!5m2!1ses!2sve",
    location: { lat: 10.250161190896408, lon: -67.8898908581805},
    address: [
      "📌 Av principal Virgirima, C.C. Piedra Pintada piso 2 local c27, Yagua.",
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 1:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],
    thumb: "assets/img/Screenshot_1.png",
    images: ["assets/img/Screenshot_1.png"]
  },
  {
    id: 3,
    name: "Centro Empresarial Leonardo DaVinci",
    ubicacion: "Lara",
    urlMaps: "https://www.google.com/maps/place/Centro+Comercial+Leonardo+Da+Vinci/@10.0688343,-69.3015365,21z/data=!4m5!3m4!1s0x8e876770c1c7ce8f:0x8308a70fd95497a6!8m2!3d10.0688295!4d-69.3014546",
    location: { lat: 10.0688448564042, lon: -69.30125888631842 },
    address: ["📌 Barquisimeto: Av.20 entre calles 9 y 10, C.E Leonardo Da Vinci, Planta B, Local 08."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/barquisimeto.jpg",
    images: ["assets/img/barquisimeto.jpg"]
  },
  {
    id: 4,
    name: "Paseo las Delicias Fibex Telecom",
    ubicacion: "Aragua",
    urlMaps: "https://www.google.com/maps/place/Fibex+Telecom/@10.2560148,-67.5917644,21z/data=!4m12!1m6!3m5!1s0x8e803b784b006abd:0x690ba19d5edbc05!2sFibex+Telecom!8m2!3d10.2559676!4d-67.5917222!3m4!1s0x8e803b784b006abd:0x690ba19d5edbc05!8m2!3d10.2559676!4d-67.5917222",
    location: { lat: 10.255980129610444, lon: -67.59171885052572 },
    address: ["📌 Maracay: C.C Paseo las Delicias II Nivel Mezzanina Local 50."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 am a 4:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/Maracay.png",
    images: ["assets/img/Maracay.png"]
  },
  {
    id: 4,
    name: "Sucursal",
    ubicacion: "Aragua",
    urlMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d490.7002576633354!2d-67.62850737946!3d10.293612047114859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e803b334261513d%3A0x190f9a27043c9092!2sFibex!5e0!3m2!1ses!2sve!4v1685130868677!5m2!1ses!2sve",
    location: { lat: 10.293652292452789, lon: -67.62851006166892 },
    address: ["📌 El Limon: Av Universidad con calle Francisco Fernandez Yepez N° S/N Sector Niño Jesús, el limón."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 am a 4:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/Maracay.png",
    images: ["assets/img/Maracay.png"]
  },
  {
    id: 4,
    name: "Sucursal",
    ubicacion: "Aragua",
    urlMaps: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d981.4006675132316!2d-67.62840929927371!3d10.293563125537192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAv%20Universidad%20con%20calle%20Francisco%20Fernandez%20Yepez%20N%C2%B0%20S%2FN%20Sector%20Ni%C3%B1o%20Jes%C3%BAs%2C%20el%20lim%C3%B3n.%20Aragua%20fibex!5e0!3m2!1ses!2sve!4v1685130558669!5m2!1ses!2sve",
    location: { lat: 10.480684673788708, lon: -66.93943020903112},
    address: ["📌 Santa Rita: Av Constitución, Centro Comercial Santa Rita Local 8."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 am a 4:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/Maracay.png",
    images: ["assets/img/Maracay.png"]
  },
  {
    id: 4,
    name: "Sucursal",
    ubicacion: "Aragua",
    urlMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d245.44249072821535!2d-67.4585905115163!3d10.17416482035779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8021e94a402a47%3A0x217dade234380831!2sCorporacion%20CCTR!5e0!3m2!1ses!2sve!4v1685114657312!5m2!1ses!2sve",
    location: { lat: 10.174146670136013, lon: -67.45841214462358 },
    address: ["📌 Cagua: Av. Gran Mariscal C.C Cahiua Local PB Número 34 Corinsa Cagua Edo. Aragua."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 am a 4:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/Maracay.png",
    images: ["assets/img/Maracay.png"]
  },
  {
    id: 4,
    name: "Sucursal",
    ubicacion: "Aragua",
    urlMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d981.615382181326!2d-67.3348141665195!3d10.224310768527955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a9f4543c8e7c9%3A0x51a92e556655478b!2sCentro%20Comercial%20Palma%20Center!5e0!3m2!1ses!2sve!4v1685114868397!5m2!1ses!2sve",
    location: { lat: 10.224723868239927, lon: -67.33360449029911},
    address: ["📌 La victoria: Av. Ppal de Soco CC Palma Center Local LC34."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 am a 4:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/Maracay.png",
    images: ["assets/img/Maracay.png"]
  },
  {
    id: 5,
    name: "Centro Comercial Sol Center",
    ubicacion: "Barinas",
    urlMaps: "https://www.google.com/maps/place/Centro+Comercial+Sol+Center/@8.6143544,-70.2584003,18z/data=!4m9!1m2!2m1!1sAvenida+Alberto+Arvelo+Torrealba+C.C+Sol+Center+1er+piso+local+%2307!3m5!1s0x8e7b57766e7a33ff:0x5df5a32dba005f16!8m2!3d8.6147777!4d-70.2577969!15sCkJBdmVuaWRhIEFsYmVydG8gQXJ2ZWxvIFRvcnJlYWxiYSBDLkMgU29sIENlbnRlciAxZXIgcGlzbyBsb2NhbCAjMDdaQyJBYXZlbmlkYSBhbGJlcnRvIGFydmVsbyB0b3JyZWFsYmEgY2Mgc29sIGNlbnRlciAxZXIgcGlzbyBsb2NhbCAjMDeSAQ9zaG9wcGluZ19jZW50ZXI",
    location: { lat: 8.614786680514984, lon: -70.25779535902208 },
    address: ["📌 Av. Alberto Arvelo Torrealba C.C Sol Center 1er piso local 07."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/Screenshot_2.png",
    images: ["assets/img/Screenshot_2.png"]
  },
  {
    id: 6,
    name: "La Guaira",
    ubicacion: "La Guaira",
    urlMaps: "https://www.google.com/maps/place/Centro+Comercial+Empresarial+Puertas+Del+Sol/@10.5952671,-66.9504811,20z/data=!4m9!1m2!2m1!1sCalle+principal+de+maiquetia+centro+comercial+puerta+del+sol+piso+2+oficinas+p-02+y+p-03.!3m5!1s0x8c2a5ce2b2fdf939:0xceeba27cbd74797e!8m2!3d10.5953124!4d-66.9503032!15sCllDYWxsZSBwcmluY2lwYWwgZGUgbWFpcXVldGlhIGNlbnRybyBjb21lcmNpYWwgcHVlcnRhIGRlbCBzb2wgcGlzbyAyIG9maWNpbmFzIHAtMDIgeSBwLTAzLlpaIlhjYWxsZSBwcmluY2lwYWwgZGUgbWFpcXVldGlhIGNlbnRybyBjb21lcmNpYWwgcHVlcnRhIGRlbCBzb2wgcGlzbyAyIG9maWNpbmFzIHAgMDIgeSBwIDAzkgEPc2hvcHBpbmdfY2VudGVy",
    location: { lat: 10.595330493183447, lon: -66.95030809612591 },
    address: ["📌 MAIQUETIA: Calle principal de Maiquetía, Piso 2, Oficina P-02, C.C Puerta del Sol."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/la guaira.png",
    images: ["assets/img/la guaira.png"]
  },
  {
    id: 6,
    name: "La Guaira",
    ubicacion: "La Guaira",
    urlMaps: "https://www.google.com/maps/place/C.+Real+de+Pariata,+Maiquet%C3%ADa+1161,+La+Guaira/@10.5965591,-66.9617786,20z/data=!4m6!3m5!1s0x8c2a5d1ab853abb1:0xfccf87cb72a8663c!8m2!3d10.5966764!4d-66.9617008!16s%2Fg%2F11bc8w904l?hl=es&entry=ttu",
    location: { lat: 10.5965591, lon: -66.9617786 },
    address: ["📌 PARIATA: calle Real de Pariata, edificio karim, PB oficina N 01."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/la guaira.png",
    images: ["assets/img/la guaira.png"]
  },
  {
    id: 6,
    name: "La Guaira",
    ubicacion: "La Guaira",
    urlMaps: "https://www.google.com/maps/place/urbanizacion+atlantida/@10.6041404,-67.0302952,19z/data=!4m6!3m5!1s0x8c2a6792f6fd03a9:0xde8f2cd26b7ddbd0!8m2!3d10.6042973!4d-67.0304508!16s%2Fg%2F11s2pznkyx?hl=es&entry=ttu",
    location: { lat: 10.6041404, lon: -67.0302952 },
    address: ["📌 CATIA LA MAR: Urbanización La Atlántida, entre calles 13 y 14. Centro comercial oceano, nivel 01. Local 11-A."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/la guaira.png",
    images: ["assets/img/la guaira.png"]
  },
  {
    id: 6,
    name: "La Guaira",
    ubicacion: "La Guaira",
    urlMaps: "https://www.google.com/maps/place/Centro+Comercial+Mar+Caribe/@10.6169055,-66.8562428,19z/data=!4m6!3m5!1s0x8c2a5b2a82e9c877:0x16d7a548b8d61fc0!8m2!3d10.6171678!4d-66.8559612!16s%2Fg%2F11b6cq3s56?hl=es&entry=ttu",
    location: { lat: 10.6169055, lon: -66.8562428 },
    address: ["📌 CARABALLEDA: Av. La Costanera, C.C. Mar Caribe, nivel mezzanina, local 08, Urbanización Los Corales."],
    description: ["Lunes a Viernes 8:00 a.m a 5:00 p.m","Nota de horario de caja: 8:00 a.m a 4:45 p.m"],
    thumb: "assets/img/la guaira.png",
    images: ["assets/img/la guaira.png"]
  },
  {
    id: 7,
    name: "Anzoátegui",
    ubicacion: "Anzoátegui",
    urlMaps: "https://www.google.com/maps/place/Centro+Comercial+La+Concha/@10.1829036,-64.6917984,17z/data=!3m1!4b1!4m5!3m4!1s0x8c2d736906a49f47:0xf567afe634e5cb1a!8m2!3d10.1829036!4d-64.6896097",
    location: { lat: 10.182921742449679, lon: -64.68961171443131 },
    address: [
      "📌 LECHERÍA: Av. Principal, frente al C.C. La Concha, Quinta Antonieta.",
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 7,
    name: "Anzoátegui",
    ubicacion: "Anzoátegui",
    urlMaps: "https://www.google.com/maps/place/P.%C2%BA+Col%C3%B3n,+Puerto+La+Cruz+6023,+Anzo%C3%A1tegui/@10.2126565,-64.64737,18z/data=!4m6!3m5!1s0x8c2d746abe4d5b2d:0xc44a5eef4ef0ba9e!8m2!3d10.2129706!4d-64.6462274!16s%2Fg%2F1thn6kcw?hl=es&entry=ttu",
    location: { lat: 10.2126565, lon: -64.64737 },
    address: [
      "📌 PTO LA CRUZ: Sector Paseo Colón, Calle Carabobo, C.C. Paseo Plaza, local N-9."
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 7,
    name: "Anzoátegui",
    ubicacion: "Anzoátegui",
    urlMaps: "https://goo.gl/maps/4pawR26T9vhebKbR6",
    location: { lat: 10.156212068746608, lon: -64.69804160180706 },
    address: [
      "📌 BARCELONA: Nueva Barcelona en el centro comercial Puente Real Local PB-B40D."
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 7,
    name: "Anzoátegui",
    ubicacion: "Anzoátegui",
    urlMaps: "https://www.google.com/maps/place/Supermercado+Campomar,+Puerto+P%C3%ADritu+6022,+Anzo%C3%A1tegui/@10.0613858,-65.0274378,19z/data=!4m6!3m5!1s0x8c2d1c21a60f1593:0x2402f5275f3c1d08!8m2!3d10.0614938!4d-65.0268072!16s%2Fg%2F11ckq2wdyk?hl=es&entry=ttu",
    location: { lat: 10.0613858, lon: -65.0274378 },
    address: [
      "📌 PTO PÍRITU: Av. principal de Campo Mar, casa Nro 05. Puerto Píritu, Municipio Fernando de Peñalver."
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 8,
    name: "Margarita",
    ubicacion: "Nueva Esparta",
    urlMaps: "https://www.google.com/maps/place/FIBEX+TELECOM/@10.9929497,-63.826577,18z/data=!4m14!1m7!3m6!1s0x8c318f923e73eded:0x119976956320d440!2sFIBEX+TELECOM!8m2!3d10.9926522!4d-63.8234656!16s%2Fg%2F11scw3p_sz!3m5!1s0x8c318f923e73eded:0x119976956320d440!8m2!3d10.9926522!4d-63.8234656!16s%2Fg%2F11scw3p_sz?entry=ttu",
    location: { lat: 10.9929497, lon: -63.826577 },
    address: [
      "📌 Av. Aldoza Manrique Sector playa el Àngel, CC Rattan Plaza PB Local 17, Municipio Maneiro, Isla de Margarita."
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 9,
    name: "Yaracuy",
    ubicacion: "Yaracuy",
    urlMaps: "https://www.google.co.ve/maps/place/Fibex+Telecom+Nirgua/@10.1533572,-68.569178,19z/data=!4m14!1m7!3m6!1s0x8e80bd0737f2800f:0xfc70be803ae12cdd!2sFibex+Telecom+Nirgua!8m2!3d10.1533084!4d-68.5688561!16s%2Fg%2F11t7ctf903!3m5!1s0x8e80bd0737f2800f:0xfc70be803ae12cdd!8m2!3d10.1533084!4d-68.5688561!16s%2Fg%2F11t7ctf903?hl=es-419&entry=ttu",
    location: { lat: 10.1533572, lon: -68.569178 },
    address: [
      "📌 Av. Bolívar Mini Centro Longo Local 5 Nirgua."
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 10,
    name: "San Juan de los Morros",
    ubicacion: "Guárico",
    urlMaps: "https://www.google.co.ve/maps/place/Av.+Miranda,+San+Juan+de+Los+Morros+2301,+Gu%C3%A1rico/@9.9133519,-67.3615308,19z/data=!4m6!3m5!1s0x8c2aa9eb688281cf:0x2fb607cc4d4ab3ab!8m2!3d9.9131485!4d-67.3612371!16s%2Fg%2F1tg4md_p?hl=es-419&entry=ttu",
    location: { lat: 9.9133519, lon: -67.3615308 },
    address: [
      "📌 Av. Miranda Locales 01 y 02 CC. Bonanza. San Juan de los Morros."
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 11,
    name: "Tinaco",
    ubicacion: "Cojedes",
    urlMaps: "https://www.google.co.ve/maps/place/C.+Bol%C3%ADvar,+San+Carlos+2201,+Cojedes/@9.652175,-68.5706646,18z/data=!4m6!3m5!1s0x8e7e167e4587105d:0x465dd675f99679a1!8m2!3d9.6521299!4d-68.57095!16s%2Fg%2F1q69p31xh?hl=es-419&entry=ttu",
    location: { lat: 9.652175, lon: -68.5706646 },
    address: [
      "📌 C.C. El Parque, Calle Bolívar con calle Miranda."
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 11,
    name: "Tinaco",
    ubicacion: "Cojedes",
    urlMaps: "https://www.google.co.ve/maps/place/Tinaco,+San+Carlos+2201,+Cojedes/@9.6552021,-68.5740689,19z/data=!4m6!3m5!1s0x8e7e16876ed1296f:0xaf909a1c55e06765!8m2!3d9.6552167!4d-68.5734091!16s%2Fg%2F11h3gfvgk7?hl=es-419&entry=ttu",
    location: { lat: 9.6552021, lon: -68.5740689 },
    address: [
      "📌 Av. Bolívar Vía Tinaco."
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 11,
    name: "Tinaco",
    ubicacion: "Cojedes",
    urlMaps: "https://www.google.co.ve/maps/place/Centro+Comercial+Paso+Real/@9.9227337,-68.3007863,19z/data=!4m6!3m5!1s0x8e8082e2f3ca32ed:0x5593300ce64c4351!8m2!3d9.9226769!4d-68.3009875!16s%2Fg%2F11g6mxdkjl?hl=es-419&entry=ttu",
    location: { lat: 9.9227337, lon: -68.3007863 },
    address: [
      "📌 C.C. Paso real Local 1-A y 1-B. Av. Miranda y Ricaurte. Sector Miranda. Norte de Tinaquillo."
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 12,
    name: "Sucursal",
    ubicacion: "Monagas",
    urlMaps: "https://www.google.co.ve/maps/place/Centro+Comercial+Paso+Real/@9.9227337,-68.3007863,19z/data=!4m6!3m5!1s0x8e8082e2f3ca32ed:0x5593300ce64c4351!8m2!3d9.9226769!4d-68.3009875!16s%2Fg%2F11g6mxdkjl?hl=es-419&entry=ttu",
    location: { lat: 10.15636716147964, lon: -64.69805253235535},
    address: [
      "📌 En el centro comercial Puente Real sector Nueva barcelona local PB-40-D, Maturín."
    ],
    description: [
      "Lunes a Viernes 8:00 a.m a 5:00 p.m",
      "Sábados de 8:00 a.m a 2:00 p.m",
      "Nota de horario de caja: 8:00 a.m a 4:45 p.m"
    ],

    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  /*   {
      id: 8,
      name: "Puerto la Cruz",
      ubicacion: "Puerto la Cruz",
      urlMaps: "https://www.google.com/maps/place/C.C+El+Paseo/@10.2236726,-64.6359192,19z/data=!4m9!1m2!2m1!1sPuerto+la+Cruz,+calle+carabobo,+prolongacion+Paseo+Colon,+Centro.!3m5!1s0x8c2d7505d37db97d:0x560cba0cffd38daa!8m2!3d10.2238315!4d-64.6356372!15sCkFQdWVydG8gbGEgQ3J1eiwgY2FsbGUgY2FyYWJvYm8sIHByb2xvbmdhY2lvbiBQYXNlbyBDb2xvbiwgQ2VudHJvLlo_Ij1wdWVydG8gbGEgY3J1eiBjYWxsZSBjYXJhYm9ibyBwcm9sb25nYWNpb24gcGFzZW8gY29sb24gY2VudHJvkgEPc2hvcHBpbmdfY2VudGVy",
      location: { lat: 10.223841398571793, lon: -64.63563720007686 },
      address: ["📌Puerto la Cruz, calle carabobo, prolongacion Paseo Colon, Centro."],
      description: "9:00AM a 4:00PM",
      thumb: "assets/img/puertoCruz.png",
      images: ["assets/img/puertoCruz.png"]
    }, */
  /* {
    id: 9,
    name: "Puerto Cabello",
    ubicacion: "Carabobo",
    urlMaps: "https://www.google.com/maps/place/C.C+Plaza+City/@10.4649845,-68.0078608,21z/data=!4m8!1m2!2m1!1sAv.+Juan+Jos%C3%A9+Flores,+CC+Plaza+City.Comercial+Fundeso,+Oficina+%237.3.!3m4!1s0x8e8053ce5cf68fcd:0x45bfd01459dce6cb!8m2!3d10.4650257!4d-68.0078011",
    location: { lat: 10.465236707368469, lon: -68.0077876890822 },
    address: ["📌Centro Comercial Vía Veneto Piso Florencia Local F-05 Naguanagua"],
    description: "9:00AM a 4:00PM",
    thumb: "assets/img/Plaza.png",
    images: ["assets/img/Plaza.png"],
  }, */
]



