<ion-app>
  <ion-split-pane contentId="menu-content">

    <ion-menu contentId="menu-content" *ngIf="ShowMenu">

      <ion-header class="app-header">
        <ion-toolbar color="dark" class="user-profile">

          <ion-item class="no_margins">
            <ion-avatar slot="start" class="user-avatar" style="margin-right: 5px;">
              <img defer async loading="lazy" [src]="UrlAvatar" style="padding: 16%;">
            </ion-avatar>
            <ion-label>
              <ion-text *ngIf="user">
                <h1 style="font-weight: bold; color: #ffffff;"> {{ subscriberUserName.substring(0,14) }} </h1>
              </ion-text>
              <ion-text *ngIf="user" style="color: #c8f8c6;">
                {{ subscriberUserEmail }}
              </ion-text>
              <div *ngIf="!kioskoApp">
                <a class="text08" tappable (click)="goToEditProgile()">
                  <ion-text color="tertiary">
                    <ion-icon name="person-circle"></ion-icon>
                    <strong>{{ 'app.pages.menu.editprofile' | translate }}</strong>
                  </ion-text>
                </a>
                <ion-text style="color: #c8f8c6;"> | </ion-text>
                <a class="text08" tappable (click)="logout()">
                  <ion-text color="tertiary">
                    <ion-icon name="log-out"></ion-icon>
                    <strong>{{ 'app.pages.menu.logout' | translate }}</strong>
                  </ion-text>
                </a>
              </div>
              <div *ngIf="kioskoApp"
                style="display: flex; flex-direction: row; align-content: stretch; justify-content: space-between;">
                <a tappable (click)="logout()" style="font-size: 20px;">
                  <ion-text color="tertiary">
                    <ion-icon name="log-out"></ion-icon>
                    <strong>{{ 'app.pages.menu.logout' | translate }}</strong>
                  </ion-text>
                  <img src="assets\img\icons\Flecha.gif" defer async loading="lazy"
                    style="transform: rotate(-90deg); width: 15%; margin-bottom: -7%; margin-left: 8%;">
                </a>

              </div>
            </ion-label>
          </ion-item>

        </ion-toolbar>
      </ion-header>

      <ion-content class="bg-profile">

        <div *ngIf="appPages?.length" class="list_container">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <div *ngIf="p.showMenu" (click)="p.onClick ? p.onClick() : navigate(p.url)" color="primary" (click)="showSaldo(p.url)">
              <ion-icon *ngIf="p.img === ''" slot="start" [name]="p.icon"></ion-icon>
              <img defer async loading="lazy" width="30" height="34" *ngIf="p.img !== ''" [src]="p.img" [alt]="p.title">
              <ion-label>
                {{p.title}}
              </ion-label>
            </div>
          </ion-menu-toggle>
        </div>

        <div *ngIf="!appPages?.length" class="list_container">
          <div *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14]" class="menu-toggle-loading loading medium-mode"></div>
        </div>


        <div class="version-container color_app_blue">
          <ion-text style="font-size: 10px;" (click)="_log.IncrementTrysToOpen()">
            {{VersionApp}}
          </ion-text>
        </div>
      </ion-content>

    </ion-menu>

    <ion-router-outlet id="menu-content">
      <app-loader></app-loader>
    
    </ion-router-outlet>

  </ion-split-pane>
</ion-app>









<!-- <ion-app>

  <div id="mySidebar" ngClass="{{ showNavBar ? 'sidebar show-nav' : 'sidebar' }}">

    <ion-header class="header">
      <ion-toolbar color="dark" class="user-profile">

        <ion-item class="no_margins">
          <ion-avatar slot="start" class="user-avatar" style="margin-right: 5px;">
            <img [src]="UrlAvatar" style="padding: 16%;">
          </ion-avatar>
          <ion-label>
            <ion-text *ngIf="user">
              <h1 style="font-weight: bold; color: #ffffff;"> {{user.fullName.substring(0,14)}} </h1>
            </ion-text>
            <ion-text *ngIf="user" style="color: #c8f8c6;">
                {{user.email}}
            </ion-text>
            <div *ngIf="!kioskoApp">
              <a class="text08" tappable (click)="goToEditProgile()">
                <ion-text color="tertiary">
                  <ion-icon name="person-circle"></ion-icon>
                  <strong>{{ 'app.pages.menu.editprofile' | translate }}</strong>
                </ion-text>
              </a>
              <ion-text style="color: #c8f8c6;"> | </ion-text>
              <a class="text08" tappable (click)="logout()">
                <ion-text color="tertiary">
                  <ion-icon name="log-out"></ion-icon>
                  <strong>{{ 'app.pages.menu.logout' | translate }}</strong>
                </ion-text>
              </a>
            </div>
            <div *ngIf="kioskoApp"
              style="display: flex; flex-direction: row; align-content: stretch; justify-content: space-between;">
              <a tappable (click)="logout()" style="font-size: 20px;">
                <ion-text color="tertiary">
                  <ion-icon name="log-out"></ion-icon>
                  <strong>{{ 'app.pages.menu.logout' | translate }}</strong>
                </ion-text>
                <img src="assets\img\icons\Flecha.gif" style="transform: rotate(-90deg); width: 15%; margin-bottom: -7%; margin-left: 8%;">
              </a>

            </div>
          </ion-label>
        </ion-item>

      </ion-toolbar>

      <div class="closebtn" (click)="closeNav()">×</div>
    </ion-header>

    <div class="list_container">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
          <div *ngIf="p.showMenu" (click)="navigate(p.url)" color="primary" (click)="showSaldo(p.url)">
            <ion-icon *ngIf="p.img === ''" slot="start" [name]="p.icon"></ion-icon>
            <img width="30" height="34" *ngIf="p.img !== ''" [src]="p.img" [alt]="p.title">
            <ion-label>
              {{p.title}}
            </ion-label>
          </div>
        </ion-menu-toggle>
      </div>
    </div>
    
    <div id="main">
    <ion-content style="height: 100vh;">
      <ion-router-outlet id="menu-content"></ion-router-outlet>
    </ion-content>
  </div>

</ion-app> -->