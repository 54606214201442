// Add your providers here for easy indexing.
export { TranslateProvider } from "./translate/translate.service";
export { HotelProvider } from "./hotel/hotel.service";
export { WeatherService } from "./weather/weather.service";
export { CarsService } from "./cars/cars.service";
export { ActivitiesService } from "./activities/activities.service";
import { MenuOptInterface } from "../interfaces/menuOpt.interface";

export const OptionMenu: MenuOptInterface[] = [

    //Until finish this section
    // {
    //     "title": "Club Fibex",
    //     "url": "/club-fibex/home",
    //     "direct": "forward",
    //     "icon": "star",
    //     "show": true,
    //     "image": 'assets/img/club_fibex_logo_negative.png',
    // },

    //This will be render
    // {
    //     "title": "Club Fibex",
    //     "url": "/club-fibex/",
    //     "direct": "forward",
    //     "icon": "star",
    //     "show": true,
    //     "image": 'assets/img/club_fibex_logo_negative.png',
    // },
    {
        "title": "Club Fibex",
        "url": "/club-fibex/home",
        "direct": "forward",
        "icon": "star",
        "show": true,
        "showHome": true,
        "showMenu": true, 
        // "image": 'assets/img/icons/Iconos1.png',
        "img": "",
        "image": "assets/img/club_fibex_logo_negative_small.png"
    },
    {
        "title": "¡RECOMIENDA Y GANA!",
        "url": "/recommend-and-win",
        "direct": "forward",
        "icon": "share-social",
        "show": true,
        "showHome": true,
        "showMenu": true,
        "img": "assets/img/svg/iconos_azules/recommendAndWin2.svg",
        "image": 'assets/img/svg/iconos_azules/recommendAndWin.svg'
    },
    // {
    //     "title": "Clave Wifi",
    //     "url": "/wifi",
    //     "direct": "forward",
    //     "icon": "star",
    //     "show": false,
    //     "showHome": false,
    //     "showMenu": false,
    //     // "image": 'assets/img/icons/Iconos1.png',
    //     "img": "assets/img/svg/iconos_azules/wifi-pass2.svg",
    //     "image": "assets/img/svg/iconos_azules/wifi-pass.svg"
    // },
    {
        "title": "Saldo",
        "url": "/home",
        "direct": "root",
        "icon": "logo-usd",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos1.png',
        "img": "assets/img/svg/iconos_azules/dolar-rolinera-icon2.svg",
        "image": "assets/img/svg/iconos_azules/dolar-rolinera-icon.svg"
    },
    {
        "title": "Reportar Pago",
        "url": "/favorites",
        "direct": "forward",
        "icon": "Cash",
        "show": true,
        "showHome": true,
      "showMenu": true,
        // "image": 'assets/img/icons/Iconos2.png'
        "img": "",
        "image": "assets/img/svg/iconos_azules/payment-card-icon.svg"
    },
    {
        "title": "Servicios",
        "url": "/services",
        "direct": "forward",
        "icon": "bulb",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos3.png'
        "img": "",
        "image": "assets/img/svg/iconos_azules/diagrama-icon.svg"
    },
    // {
    //     "title": "Abonado(s)",
    //     "url": "/rentcar",
    //     "direct": "forward",
    //     "icon": "ios-people",
    //     "show": true,
    //     "image": 'assets/img/icons/Iconos7.jpg'
    // },
    {
        "title": "Planes",
        "url": "/planes",
        "direct": "forward",
        "icon": "cart",
        "show": true,
        "showHome": false,
        "showMenu": false,
        // "image": 'assets/img/icons/iconPlan.png'
        "img": "",
        "image": "assets/img/svg/iconos_azules/document-icon.svg"
    },
    {
        "title": "Promociones",
        "url": "/booking-list",
        "direct": "forward",
        "icon": "ribbon",
        "show": true,
        "showHome": false,
        "showMenu": false,
        "img": 'assets/img/icons/Iconos6.png',
        "image": "assets/img/svg/iconos_azules/bombillo-icon.svg"
    },
    {
        "title": "FibexPlay",
        // "url": "/local-weather",
        "url": "",
        "direct": "forward",
        // "icon": "ios-tv",
        "icon": "desktop",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos4.jpg'
        "img": "assets/img/svg/iconos_azules/cloud-icon2.svg",
        "image": "assets/img/svg/iconos_azules/cloud-icon.svg"
    },
    {
        "title": "Control",
        "url": "/fibex-remote-control",
        "direct": "forward",
        // "icon": "ios-phone-portrait",
        "icon": "phone-portrait",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/iconControl.png'
        "img": "assets/img/svg/iconos_azules/mouse-icon2.svg",
        "image": "assets/img/svg/iconos_azules/mouse-icon.svg"
    },
    {
        "title": "Suscriptor(es)",
        "url": "/rentcar",
        "direct": "forward",
        // "icon": "ios-people",
        "icon": "people",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos7.jpg'
        "img": "",
        "image": "assets/img/svg/iconos_azules/person-great-icon.svg"
    },
    {
        "title": "Mi equipo",
        "url": "/mi-equipo",
        "direct": "forward",
        "icon": "easel",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/mi-equipo.png'
        "img": "assets/img/svg/iconos_azules/phone-icon2.svg",
        "image": "assets/img/svg/iconos_azules/phone-icon.svg"
    },
    {
        "title": "Factura",
        "url": "/factura",
        "direct": "forward",
        "icon": "newspaper-sharp",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/referidos.png'
        "img": "assets/img/svg/iconos_azules/money-house-icon2.svg",
        "image": 'assets/img/svg/iconos_azules/money-house-icon.svg'
    },
/*     {
        "title": "Referidos",
        "url": "/referidos",
        "direct": "forward",
        "icon": "person-add",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/referidos.png'
        "img": "",
        "image": 'assets/img/svg/iconos_azules/tabla-dardos-icon.svg'
    }, */
    {
        "title": "Contacto",
        "url": "/activities",
        "direct": "forward",
        "icon": "people-circle",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos8.png'
        "img": "",
        "image": 'assets/img/svg/iconos_azules/local-cell-phone.svg'
    },
    {
        "title": "Manuales",
        "url": "/manuales",
        "direct": "forward",
        "icon": "document",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/iconManual.png'
        "img": "",
        "image": "assets/img/svg/iconos_azules/books-icon.svg"

    },
    {
        "title": "Soporte",
        "url": "/support",
        "direct": "forward",
        "icon": "build",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos5.png'
        "img": "",
        "image": "assets/img/svg/iconos_azules/support.svg"
    },
    {
        "title": "Velocidad",
        "url": "/speedtest",
        "direct": "forward",
        "icon": "paper-plane",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos1.png'
        "img": "",
        "image": "assets/img/svg/iconos_azules/paper.svg"
    },
    {
        "title": "Preguntas frecuentes",
        "url": "/about",
        "direct": "forward",
        "icon": "information-circle",
        "show": true,
        "showHome": true,
        "showMenu": true,
        "img": "",
        "image": 'assets/img/svg/iconos_azules/search-icon.svg'
    },
    {
        "title": "Seguridad",
        "url": "/security",
        "direct": "forward",
        "icon": "lock-closed-sharp",
        "show": true,
        "showHome": true,
        "showMenu": true,
        "img": "assets/img/svg/iconos_azules/security-alt2.svg",
        "image": 'assets/img/svg/iconos_azules/security-alt.svg'
    }    
]

export const personalizedMenu: MenuOptInterface[] = [
    {
        "title": "Clave Wifi",
        "url": "/wifi",
        "direct": "forward",
        "icon": "star",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos1.png',
        "img": "assets/img/svg/iconos_azules/wifi-pass2.svg",
        "image": "assets/img/svg/iconos_azules/wifi-pass.svg"
    }
]

export const metodosPagoImages = [
    "assets/img/metodos-pago-images/Paypal_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/BitCoin_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/MasterCard_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Mercantil_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Ethereum_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Visa_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/BNC_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Zelle_Mesa de trabajo 1.png",
]

