import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class SaveErrorLogService {
    /** Tipo del error */
    private TipoError: string = ""
    /** Todos los logs compartidos */
    private static logs: {
        text: string,
        type: "warn" | "error" | "log"
    }[] = [];
    /** Valor para saber si ya fué asignado un método del console.log */
    private static consoleLogDefined: boolean = false;
    /** Events */
    private static callbacks: {type: string, callback: Function, id: number}[] = [];
    /** id handler */
    private static idHandler: number = 0;
    public textDebugg: {color: string, body: string}[] = [];

    /**
     * Permite registrar
     * @param type tipo evento a registrar
     * @param callback función asociada
     * @returns Id del evento registrado
     */
    public on(type: "add", callback: (value: string, type: "warn" | "log" | "error") => void): number;
    public on(type: string, callback: Function): number {
        SaveErrorLogService.callbacks.push({
            type: type,
            callback: callback,
            id: ++SaveErrorLogService.idHandler
        });
        return SaveErrorLogService.idHandler;
    }

    /**
     * Eliminar un registro event emiter
     */
    public removeListenner(id: number): void {
        SaveErrorLogService.callbacks = SaveErrorLogService.callbacks.filter(c => c.id !== id);
    }

    /**
     * Permite emitir un evento de tipo add
     * @param type nombre evento
     * @param textLog texto asociado
     */
    private emit(type: "add", textLog: string, typeLog: "warn" | "log" | "error"): void;
    private emit(type: string, ...args: unknown[]): void {
        SaveErrorLogService.callbacks.forEach(callback => {
            if(callback.type === type) {
                callback.callback(...args);
            }
        })
    }

    /**
     * Constructor del servicio
     */
    constructor()  {
        /**
         * El uso de **SaveErrorLog.consoleLogDefined** ayudará en caso que se creen multiples instancias de SaveErrorLog, no se vuelva a modificar
         * la estructura del console.log, cosa que se hace en las siguientes líneas de código
         */
        // if(!SaveErrorLogService.consoleLogDefined) {
        //     SaveErrorLogService.consoleLogDefined = true;

            if(environment.production) {
                console.log = () => { }
                console.error = () => { }
                console.warn = () => { }
            }

            // /** Servicio de la console */
            const log = console.log.bind(console);
            const error = console.error.bind(console);
            const warn = console.warn.bind(console);

            // console.log = (arg: unknown, ...args: any[]) => {
            //     this.add("log", arg, ...args);
            //     log(arg, ...args);
            // }
            // console.error = (arg: unknown, ...args: any[]) => {
            //     this.add("error", arg, ...args);
            //     error(arg, ...args);
            // }
            // console.warn = (arg: unknown, ...args: any[]) => {
            //     this.add("warn", arg, ...args);
            //     warn(arg, ...args);
            // }
        // }
    }


    /**
     * Permite agregar un nuevo registro log
     * @param textLog texto del registro
     */
    public add(type: "warn" | "error" | "log", arg: unknown, ...args: unknown[]): number {
        args.unshift(arg);
        let logs: string[] = [];

        for(let textLog of args) {
            let resultLog: string;

            try {
                /** Verificar si se trata ya de un error */
                if(textLog instanceof Error) {
                    type = "error";
                    resultLog = (textLog.stack || ("ERROR: " + textLog.name + " >>>> " + textLog.message));
                }
                /** Verificar si se trata de un objeto */
                else if(textLog instanceof Object) {
                    resultLog = JSON.stringify(textLog, null, '    ');
                }
                else {
                    throw new Error("Not modified result");
                }
            }
            catch(err) {
                resultLog = String(textLog);
            }

            logs.push(resultLog);
        }
        /** Agregar log */
        const logRow = logs.join(" ");
        SaveErrorLogService.logs.push({
            type: type,
            text: logRow
        });
        this.emit("add", logRow, type);

        return SaveErrorLogService.logs.length;
    }


    /**
     * Vaciar todos los registros
     */
    public clear() {
        SaveErrorLogService.logs = [];
    }


    /**
     * Obtener todos los logs
     */
    public getAll(): readonly {
        type: "warn" | "error" | "log",
        text: string
    }[] {
        /** TENER CUIDADO SE ESTÁ RETORNANDO UNA REFERENCIA Y NO UNA COPIA DE LOS REGISTROS */
        return SaveErrorLogService.logs;
    }


    /**
     * Retorna el texto que puede ser guardado en algún archivo.txt
     */
    public save() {
        return SaveErrorLogService.logs.join("\n");
    }
}
