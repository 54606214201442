import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { SeguridadDatos } from './bscript.service';
import { environment as env } from '../../environments/environment';
import { AlertController, NavController } from '@ionic/angular';
import { timeout } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./bscript.service";
import * as i3 from "@ionic/angular";
export class HelperService {
    constructor(http, crypto, navigate, alert) {
        this.http = http;
        this.crypto = crypto;
        this.navigate = navigate;
        this.alert = alert;
        this.currentOptionsSubject = new BehaviorSubject({});
        this.currentIframeRendererSubject = new BehaviorSubject({ url: '', title: '' });
        this.showScrollArrowSubject = new BehaviorSubject(false);
        this.menu = new Subject();
        this.currentOptions = this.currentOptionsSubject.asObservable();
        this.currentIframeRenderer = this.currentIframeRendererSubject.asObservable();
        this.auxMenu = new EventEmitter();
    }
    sendBillsByWhatsApp(dni, filename, url) {
        console.log('ENTRANDO A METODO SERVCIO FACTURACION WHATS');
        return new Promise((resolve, reject) => {
            try {
                this.http.post(`${env.URLApi}`, {
                    tipo: this.crypto.encrypt("Recibo"),
                    cedula: this.crypto.encrypt(dni),
                    NameTipo: this.crypto.encrypt(filename),
                    url: this.crypto.encrypt(url)
                }, {
                    headers: {
                        directory: this.crypto.encrypt("Envio"),
                        method: this.crypto.encrypt(env.pc_method),
                        token: this.crypto.encrypt(env.TokenUpdate),
                        platform: this.crypto.encrypt(env.pc_platform),
                    },
                }).pipe(timeout(6000)).subscribe((res) => {
                    resolve(this.crypto.Descryp(res));
                }, (error) => {
                    reject(error);
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }
    hash_value(value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const headers_template = {
                    directory: "Consultas",
                    method: 'GenerateLicenciaApp',
                    token: env.TokenUpdate,
                    platform: "App",
                    clientlic: value,
                };
                try {
                    this.crypto.EncrypDataHash(headers_template).then((headers) => {
                        console.log('heders', headers);
                        this.http.get(`${env.URLApi}`, { headers }).subscribe(res => {
                            res = this.crypto.Descryp(res);
                            resolve(res.Licencia);
                        });
                    });
                }
                catch (error) {
                    reject(error);
                }
            });
        });
    }
    unhash_value(value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const headers_template = {
                    directory: "Consultas",
                    method: `GetLicenciaApp`,
                    token: env.TokenUpdate,
                    platform: "App",
                    clientlic: value,
                };
                try {
                    this.crypto.EncrypDataHash(headers_template).then((headers) => {
                        this.http.get(`${env.URLApi}`, { headers }).subscribe(res => {
                            res = this.crypto.Descryp(res);
                            resolve(res.Licencia);
                        });
                    });
                }
                catch (error) {
                    reject(error);
                }
            });
        });
    }
    post_license_information(subscriber, email, dni) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    return this.http.post(`${env.ApiBackendThomas}create-info`, {
                        Abonado: subscriber,
                        Correo: email,
                        Cedula: dni,
                        plataform: 'OficinaMovil'
                    }, {
                        headers: {
                            'TokenAuthPlataform': env.TokenBackendFull,
                            'Authorization': 'Basic ' + btoa(env.UserBackendFull + ':' + env.PasswordBackendFull),
                            'db': this.crypto.encrypt(env.NameBD),
                            'table': this.crypto.encrypt('tmLicesingDinamic'),
                            'x-keys-to-add-id': '["idGenerated"]',
                            'x-keys-of-arrays': '[]',
                            'x-relations': 'false',
                        }
                    }).subscribe(res => {
                        resolve(res);
                    });
                }
                catch (error) {
                    reject(error);
                }
            });
        });
    }
    get_license_information(dni) {
        return new Promise((resolve, reject) => {
            try {
                this.http.get(`${env.ApiBackendThomas}`, {
                    headers: {
                        'TokenAuthPlataform': env.TokenBackendFull,
                        'db': this.crypto.encrypt(env.NameBD),
                        'table': this.crypto.encrypt('tmLicesingDinamic'),
                        'type': this.crypto.encrypt(env.type_specific_search),
                        'campo': this.crypto.encrypt('Cedula'),
                        'valor': this.crypto.encrypt(dni),
                        'Authorization': `${'Basic ' + btoa(`${env.UserBackendFull}:${env.PasswordBackendFull}`)}`,
                        'Content-Type': 'application/json'
                    }
                }).subscribe(res => {
                    resolve(res);
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }
    setOptionsMenu(optionsMenu) {
        this.currentOptionsSubject.next(optionsMenu);
    }
    setIframeToRender(values) {
        this.currentIframeRendererSubject.next(values);
    }
    retrievePlatform() {
        const platform = navigator.userAgent.toLowerCase().replace(/\W+/g, "");
        const matched = platform.match(/smarttv/i)
            || this.GetFireTV(platform)
            || platform.match(/iphone/i)
            || platform.match(/ipad/i)
            || platform.match(/ipod/i)
            || platform.match(/blackberry/i)
            || platform.match(/android/i)
            || platform.match(/webos/i)
            || platform.match(/windowsphone/i)
            || platform.match(/windows/i)
            || platform.match(/mac/i)
            || platform.match(/linux/i)
            || platform.match(/eaglewatch/i);
        if (matched) {
            const platform = matched[0];
            switch (platform) {
                case "linux":
                case "mac":
                case "windows":
                    return "desktop";
                    break;
                case "smarttv":
                case "webos":
                case "firetv":
                    return "tv";
                    break;
                case "android": // moviles
                case "blackberry":
                case "windowsphone":
                case "eaglewatch":
                case "ipod": // tables
                case "ipad":
                    return "phone";
                    break;
            }
        }
    }
    GetFireTV(platform) {
        if (platform.includes("android")) {
            const models = [
                "AFTKA002",
                "AFTKAUK002",
                "AFTHA004",
                "AFTLBT962E2",
                "AEOHY",
                "AFTTIFF43",
                "AFTGAZL",
                "AFTANNA0",
                "AFTHA001",
                "AFTMON001",
                "AFTMON002",
                "AFTJULI1",
                "AFTANNA0",
                "AFTHA003",
                "AFTKA",
                "AFTLFT962X3",
                "AFTTIFF43",
                "AFTTI43",
                "AFTHA001",
                "AFTPR001",
                "AFTBU001",
                "AFTWMST22",
                "AFTTIFF55",
                "AFTWI001",
                "AFTSSS",
                "AFTSS",
                "AFTDCT31",
                "AFTDCT31",
                "AFTT",
                "AFTBAMR311",
                "AFTEAMR311",
                "AFTKMST12",
                "AFTLE",
                "AFTR",
                "AFTEUFF014",
                "AFTEU014",
                "AFTSO001",
                "AFTMM",
                "AFTEU011",
                "AFTJMST12",
                "AFTA",
                "AFTMM",
                "AFTT",
                "AFTRS",
                "AFTN",
                "AFTS",
                "AFTM",
                "AFTB",
                "AFTMM",
                "AFTHA002",
            ].map(i => i.toLowerCase());
            const result = models.find(m => platform.includes(m));
            if (result)
                return ["firetv"];
        }
    }
    listenYourVariable() {
        return this.menu.asObservable();
    }
    yourVariableObserver(value) {
        this.menu.next(value);
    }
    // public handleShowScrollObservable(ev): Observable<boolean> {
    //   console.log('ejecuted fn handleShowScrollArrow')
    //   this.showScrollArrowSubject.next((ev.detail.scrollTop > 0))
    //   console.log('this.showScrollArrowSubject', this.showScrollArrowSubject)
    //   return this.showScrollArrowSubject.asObservable()
    // }
    handleShowScrollArrow(ev) {
        console.log('handleShowScrollArrow ev', (ev.detail.scrollTop > 0));
        return (ev.detail.scrollTop > 0);
    }
    formatLocaleCurrency(number) {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1.';
        let arr = number.toString().split('.');
        arr[0] = arr[0].replace(exp, rep);
        return arr[1] ? arr.join(',') : arr[0];
    }
    redirectToURL(url, message) {
        if (url && url.length > 0) {
            const regexWaMe = /^(https?:\/\/wa\.me\/)(\+?\d{1,7}[-\s]?)?\d+$/;
            const urlRedirect = url.replace(" ", "").trim();
            console.log();
            if (regexWaMe.test(urlRedirect)) {
                const arr = urlRedirect.split("/");
                console.log(arr[arr.length - 1]);
                arr[arr.length - 1] = arr[arr.length - 1].replace("-", "");
                console.log(arr);
                console.log(arr.join("/"));
                const redirection = `${arr.join("/")}?text=${message}`;
                window.open(redirection, '_blank');
            }
            else {
                console.log("false");
                window.open(url, '_blank');
            }
        }
    }
    redirectToURLV2(urlRed) {
        const AndroidShareHandler = window.AndroidShareHandler;
        console.log(AndroidShareHandler);
        if (AndroidShareHandler) {
            const intentUrl = urlRed.replace(/https?:\/\//, 'intent://');
            // Construir el Intent completo
            const finalUrl = `${intentUrl}#Intent;scheme=https;end`;
            // Redirigir al Intent para abrir en Chrome u otro navegador
            window.location.href = finalUrl;
        }
        else {
            //   const element = document.createElement("a");
            //   element.href = url;
            //   element.target = "_blank"
            //   element.click();
            window.open(urlRed, '_blank');
        }
    }
}
HelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelperService_Factory() { return new HelperService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SeguridadDatos), i0.ɵɵinject(i3.NavController), i0.ɵɵinject(i3.AlertController)); }, token: HelperService, providedIn: "root" });
