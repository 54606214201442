/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./loader.component";
import * as i6 from "../../services/routing-app.service";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
function View_LoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "spinner_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ion-spinner", [["color", "secondary"], ["name", "circular"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(2, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null)], function (_ck, _v) { var currVal_0 = "secondary"; var currVal_1 = "circular"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLoader; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 245760, null, 0, i5.LoaderComponent, [i6.RoutingAppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-loader", i5.LoaderComponent, View_LoaderComponent_Host_0, { showLoader: "showLoader" }, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
