import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class ShowSaldoService {
    constructor() {
        this.menu = new EventEmitter();
    }
    setMenu(url) {
        this.menu.emit(url);
    }
}
ShowSaldoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShowSaldoService_Factory() { return new ShowSaldoService(); }, token: ShowSaldoService, providedIn: "root" });
