import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
// import { PropagandaPage } from './pages/modal/propaganda/propaganda.page';
import { Platform, MenuController, ModalController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateProvider } from './providers/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { UserAuthenticationService } from './services/user-authentication.service';
import { ConsultasService } from './services/consultas.service';
import { SubscriptionGraphqlService } from "./services/subscription-graphql.service";
import { ShowSaldoService } from './services/show-saldo.service';
import { LocalStorageService } from './services/local-storage.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { EstadisticasService } from './services/estadisticas.service';
import { LogErrorsService } from './services/log-errors.service';
import { ClearCacheService } from './services/clear-cache.service';
import { OneSignalService } from './services/one-signal.service';
import { personalizedMenu } from './providers';
import { HelperService } from './services/helper.service';
import { RoutingAppService } from './services/routing-app.service';
import { ConsoleMobileClass } from './services/log/console.mobile.service';
import { SubscriberService } from './core/services/subscriber.service';
import { NetworkStatusService } from './services/network-status.service';
import { SeguridadDatos } from './services/bscript.service';
import { QRService } from './services/qr.service';
/**
 * Main Wrap App Component with starting methods
 *
 * @export
 * @class AppComponent
 */
export class AppComponent {
    constructor(platform, splashScreen, statusBar, translate, translateService, authentication, menu, consultService, showSaldoService, _SubscriptionGraphqlService, _localStorageService, modalCtrl, idle, cd, alert, estadisticas, _logs, cache, onesignal, helper, _routing, _log, _subscriberService, networkStatus, _security, qrService) {
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.translate = translate;
        this.translateService = translateService;
        this.authentication = authentication;
        this.menu = menu;
        this.consultService = consultService;
        this.showSaldoService = showSaldoService;
        this._SubscriptionGraphqlService = _SubscriptionGraphqlService;
        this._localStorageService = _localStorageService;
        this.modalCtrl = modalCtrl;
        this.idle = idle;
        this.cd = cd;
        this.alert = alert;
        this.estadisticas = estadisticas;
        this._logs = _logs;
        this.cache = cache;
        this.onesignal = onesignal;
        this.helper = helper;
        this._routing = _routing;
        this._log = _log;
        this._subscriberService = _subscriberService;
        this.networkStatus = networkStatus;
        this._security = _security;
        this.qrService = qrService;
        this.VersionApp = environment.version;
        this.ShowMenu = true;
        this.kioskoApp = environment.kioskoApp;
        this.UrlAvatar = "/assets/img/Globo46x46.png";
        this.sidemenu = false;
        this.subscriberUserName = '';
        this.subscriberUserEmail = '';
        this.user = null;
        this.propaganda = false;
        this.isAlertOpen = false;
        this.showNavBar = false;
        /**
         * Creates an instance of AppComponent.
         * @param {Platform} platform
         * @param {SplashScreen} splashScreen
         * @param {StatusBar} statusBar
         * @param {TranslateProvider} translate
         * @param {TranslateService} translateService
         * @memberof AppComponent
         */
        this.idleState = "NOT_STARTED";
        this.countdown = null;
        this.lastPing = null;
        this.initSubscriberListener();
        this._routing.subRouterEvents(); //! to start subscription of the router events
        //!Menu local para pruebas
        // this.optionsMenu = OptionMenu
        this.consultService.GetMenu().then(res => {
            // console.log(res)
            //Este metodo es para insertar modulo según la condición del usuario que ingrese a la App
            this.MenuPersonalized();
            //! Menu que viene del API
            //   console.log('this.optionsMenu', this.optionsMenu)
            this.optionsMenu = res;
            // console.log(">>>>>>", this.optionsMenu);
            this.estadisticas.GetAcciones().then((Res) => {
                // console.log("Tengo las acciones")
                // console.log(Res)
                this.estadisticas.ListAcciones = Res;
            }).catch(err => console.error(err));
            this.estadisticas.GuardarAcciones();
            const user = this.authentication.getUser();
            // console.log(user);
            this.UniqueIdDevice = this._localStorageService.get('Unique_IdDevice');
            if (user) {
                this.subscriberUserName = user.cliente || "";
                this.subscriberUserEmail = user.email || "";
                this.consultService.GetAvatar(user.telefono).then((ResAvatar) => {
                    if (ResAvatar && ResAvatar.UrlImg) {
                        this.UrlAvatar = ResAvatar.UrlImg;
                    }
                    else {
                        this.UrlAvatar = "/assets/img/Globo46x46.png";
                    }
                }).catch((error) => {
                    console.error(error);
                    this.UrlAvatar = "/assets/img/Globo46x46.png";
                });
            }
            else {
                this.UrlAvatar = "/assets/img/Globo46x46.png";
            }
            if (environment.kioskoApp) {
                this.initTimer();
                this.consultService.menuOpcionesReference = this.appPages = this.optionsMenu.filter(option => (
                //option.url !== '/club-fibex/home' &&
                option.url !== '/planes' &&
                    option.url !== '/booking-list' &&
                    option.url !== '' &&
                    option.url !== '/fibex-remote-control' &&
                    option.url !== '/rentcar' &&
                    option.url !== '/factura' &&
                    option.url !== '/referidos' &&
                    option.url !== '/speedtest' &&
                    option.url !== '/recommend-and-win' &&
                    option.url !== '/wifi' &&
                    option.url !== '/security'));
                // console.log(this.appPages)
            }
            else {
                this.consultService.menuOpcionesReference = this.appPages = this.optionsMenu;
            }
            // console.log(this.optionsMenu)
            // console.log(this.appPages)
            this.helper.listenYourVariable().subscribe(res => {
                this.showNavBar = !this.showNavBar;
            });
            this.initializeApp();
            this.getUserData();
            /* this.getInfo.getIpFromUser()
              .subscribe(res => this._localStorageService.set('ipUser', res)); */
            //this.guia.loadGuia();
            this.GenerateIdDevice();
            // console.log("Usuario desde el constructor",this.user);
            // const versionPrueba = 'v2.9.22'
            this.consultService.VersionControl(this.user && this.user.cedula, this.VersionApp)
                .then((data) => {
                // {
                //   "Tipo":"App",
                //   "url":""
                // }
                if (data) {
                    if (data.url == 'https://app.fibextelecom.net/') {
                        //console.log("La aplicacion necesita actualizarse");
                        this.mostrarAlerta('web');
                        this.cache.clear();
                        this.reset();
                    }
                }
            }).catch((error) => { console.error("this.consultService.VersionControl", error); });
            this.consultService.ChangeEmail.subscribe((DataUser) => {
                this.user = DataUser;
            });
        });
        // const url = new URL(location.href);
        // // Validar si se trata de una aplicación movíl
        // if (!(ismobile() || !environment.production || url.searchParams.get("web_authorization") == "true")) {
        //     location.href = "https://app.fibextelecom.net/download";
        // }
        // instance.interceptors.response.use((value) => {
        //   console.log(value);
        // }, (err) => {
        //   console.error(err)
        //   if(axios.isAxiosError(err)) {
        //     if(err.response && err.response.data && typeof err.response.data.message === "string") {
        //       console.log(this._security.Descryp(err.response.data.message));
        //     }
        //   }
        // })
    }
    /**
     * Creates an Array instance with <Pages> interface that receives all menu list.
     *
     * @type {Array<Pages>}
     * @memberof AppComponent
     */
    get appPages() {
        return this.consultService.menuOpcionesReference;
    }
    set appPages(data) {
        this.consultService.menuOpcionesReference = data;
    }
    //
    mostrarAlerta(type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (type === 'web') {
                const alert = yield this.alert.create({
                    header: 'Alerta',
                    message: 'Su versión es antigua, por lo tanto necesita actualizarse',
                    cssClass: 'secondary cust',
                    buttons: [
                        {
                            text: 'Aceptar',
                            handler: () => {
                                //this.logout();
                            }
                        }
                    ]
                });
                yield alert.present();
            }
            else {
                const alert = yield this.alert.create({
                    header: 'Alerta',
                    message: 'Su versión es antigua, por lo tanto necesita actualizarse',
                    buttons: [
                        {
                            text: 'Aceptar',
                            handler: () => {
                                window.open('https://play.google.com/store/apps/details?id=com.jermsoft.fibexapp.v2', '_system');
                                //this.logout();
                            }
                        }
                    ]
                });
                yield alert.present();
            }
        });
    }
    MenuPersonalized() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.user = this.authentication.getUser();
            //Valido si cumple con la condición para el wifi
            if (this.user) {
                //Equipos del usuario
                const ResEquipos = yield this.consultService.InfoEquiposClientes(this.user.cedula);
                const equipos = ResEquipos.length;
                if (equipos > 0) {
                    //   console.log("Me subscribi");
                    this.consultService.MenuPersonalized.subscribe((data) => {
                        //console.log(data)
                        if (data) {
                            //console.log("Me llego al data")
                            // console.log('menu')
                            // console.table(this.slideItems)
                            const found = this.appPages.findIndex(route => route.url === '/wifi');
                            if (found === -1)
                                this.appPages.unshift(personalizedMenu[0]);
                        }
                        else {
                            //console.log("No llego al data")
                            this.consultService.MenuPersonalized.unsubscribe();
                        }
                    });
                    // this.consultService.DisEventMenu(this.user);
                }
                else {
                    console.log(this.consultService.MenuPersonalized);
                    this.consultService.menuOpcionesReference = this.appPages = this.appPages.filter(route => route.url !== '/mi-equipo');
                }
            }
        });
    }
    /**
     * Method that starts all Cordova and Factories
     *
     * @memberof AppComponent
     */
    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            setTimeout(() => {
                this.splashScreen.hide();
            }, 1000);
            // Set language of the app.
            this.translateService.setDefaultLang(environment.language);
            this.translateService.use(environment.language);
            this.translateService.getTranslation(environment.language).subscribe(translations => {
                this.translate.setTranslations(translations);
            });
        }).catch((error) => {
            // Set language of the app.
            this.translateService.setDefaultLang(environment.language);
            this.translateService.use(environment.language);
            this.translateService.getTranslation(environment.language).subscribe(translations => {
                this.translate.setTranslations(translations);
            });
            this._logs.insertarError(error.message);
        });
    }
    GenerateIdDevice() {
        try {
            if (!this.UniqueIdDevice) {
                const id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(16);
                this._localStorageService.set('Unique_IdDevice', id);
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    /**
     * Navigate to Edit Profile Page
     *
     * @memberof AppComponent
     */
    goToEditProgile() {
        this._routing.ChangeRuta('edit-profile');
        this.closeMenu();
    }
    /**
     * Logout Method
     *
     * @memberof AppComponent
     */
    ngOnInit() {
        //Con esto siempre limpio cache al cliente ya que no cierran sesión
        //console.log('pasando por el onInit')
        this.cache.clear();
        /*this.consultService.VersionControl(this.user && this.user.identidad, this.VersionApp)
        .then((data: any)=>{
          // {
          //   "Tipo":"App",
          //   "url":""
          // }
          if(data){
            if (data.url == 'https://app.fibextelecom.net/'){
              console.log("La aplicacion necesita actualizarse");
              this.cache.clear();
              this.reset();
            }
         }
        }
        ).catch((error:any)=>{console.log("this.consultService.VersionControl",error)});*/
        if (environment.kioskoApp) {
            this.reset();
        }
        if (this.user) {
            this.consultService.ServerDisponibles(this.user.cedula).then((ResServer) => {
                if (Object.keys(ResServer).length > 0) {
                    environment.ApiGraphQl = ResServer.ApiGraphQl;
                    environment.UrlSubscriptions = ResServer.UrlSubscriptions;
                    environment.apiChatBot = ResServer.apiChatBot;
                    environment.apiMaps = ResServer.apiMaps;
                    environment.apiPhP = ResServer.apiPhP;
                    if (ResServer.OnSuscription) {
                        this._SubscriptionGraphqlService.initSubscription();
                    }
                }
            }).catch((error) => {
                this._logs.insertarError(error.message);
            });
            /* this.consultService.VersionControl(this.user.identidad, this.VersionApp).then((res: any) => {
              if (res.url) {
                this.ShowMenu = false
                this.navCtrl.navigateForward('NewUpdate');
              }
            }) */
        }
        this._SubscriptionGraphqlService.OpSelect.subscribe((op) => {
            switch (op.option) {
                case "op12-1":
                    this.menu.open();
                    break;
                case "op12-2":
                    this.menu.close();
                    break;
            }
        });
    }
    closeMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (yield this.menu.isOpen()) {
                yield this.menu.close();
                yield this.menu.enable(false);
            }
        });
    }
    getUserData() {
        this.user = this.authentication.getUser();
        this.authentication.userState.subscribe((userData) => this.user = userData);
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.showNavBar = false;
            let Wifi = this.appPages.findIndex((Menu) => Menu.title == "Clave Wifi");
            //Wifi
            if (Wifi != -1)
                this.consultService.menuOpcionesReference = this.appPages = yield this.consultService.GetMenu();
            this.qrService.logOutToken();
            this.consultService.Logout();
        });
    }
    showSaldo(url) {
        this.showSaldoService.setMenu(url);
    }
    initTimer() {
        const user = this.authentication.getUser();
        if (user) {
            this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
            this.idle.setTimeout(30); // how long can they be idle before considered timed out, in seconds
        }
        else {
            this.idle.setIdle(30);
            this.idle.setTimeout(30);
        }
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
        // do something when the user becomes idle
        this.idle.onIdleStart.subscribe(() => {
            this.idleState = "IDLE";
            const user = this.authentication.getUser();
            if (user) {
                this.createAlert("La sesión finalizará en: 30s");
                this.isAlertOpen = true;
            }
            else {
                // this.openPropaganda();
                this.propaganda = true;
            }
        });
        // do something when the user is no longer idle
        this.idle.onIdleEnd.subscribe(() => {
            const user = this.authentication.getUser();
            if (user) {
                if (this.isAlertOpen) {
                    this.isAlertOpen = false;
                    this.alert.dismiss();
                }
                this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
                this.idle.setTimeout(30);
            }
            else {
                if (this.propaganda) {
                    this.modalCtrl.dismiss();
                    this.propaganda = false;
                }
                this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
                this.idle.setTimeout(120);
            }
            this.idleState = "NOT_IDLE";
            this.countdown = null;
            this.cd.detectChanges(); // how do i avoid this kludge?
        });
        // do something when the user has timed out
        this.idle.onTimeout.subscribe(() => {
            const user = this.authentication.getUser();
            if (user) {
                if (this.isAlertOpen) {
                    this.alert.dismiss();
                    this.isAlertOpen = false;
                    this.logout();
                }
                this.idle.setIdle(60);
                this.idle.setTimeout(30);
            }
            else {
                if (this.propaganda) {
                    this.propaganda = false;
                    this.modalCtrl.dismiss();
                }
                this.idle.setIdle(60);
                this.idle.setTimeout(120);
            }
            this.idleState = "TIMED_OUT";
            this.reset();
        });
        // do something as the timeout countdown does its thing
        this.idle.onTimeoutWarning.subscribe(seconds => {
            this.countdown = seconds;
        });
        // set keepalive parameters, omit if not using keepalive
        //this.keepalive.interval(15); // will ping at this interval while not idle, in seconds
        //this.keepalive.onPing.subscribe(() => this.lastPing = new Date()); 
    }
    reset() {
        // we'll call this method when we want to start/reset the idle process
        // reset any component state and be sure to call idle.watch()
        this.idle.watch();
        this.idleState = "NOT_IDLE";
        this.countdown = null;
        this.lastPing = null;
    }
    createAlert(header) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alert.create({
                header,
                buttons: [
                    {
                        text: 'Cerrar',
                        role: 'Cancelar',
                        cssClass: 'secondary',
                    }
                ]
            });
            alert.present();
        });
    }
    /*async openPropaganda() {
        const modal = await this.modalCtrl.create({
            component: PropagandaPage,
            cssClass: 'small-modal'
        }
        );
        await modal.present();
    }*/
    navigate(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (url === '')
                window.open('https://play.google.com/store/apps/details?id=com.tvappwatch.fibextelecom', '_system', 'location=yes');
            else
                this._routing.ChangeRuta(url);
        });
    }
    closeNav() {
        this.showNavBar = false;
    }
    initSubscriberListener() {
        this._subscriberService.currentSubscriber$.subscribe(res => {
            console.log("UPDATE MENU PERFIL::::", this.authentication.getUser());
            this.updateSubscriberInformation();
        });
    }
    updateSubscriberInformation() {
        // console.log('LISTENING')
        const user = this.authentication.getUser();
        if (user) {
            this.user = user;
            this.subscriberUserName = this.user.cliente;
            this.subscriberUserEmail = this.user.email;
        }
    }
}
