import { Pipe, PipeTransform } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser'

@Pipe({
  name: 'url'
})
export class UrlPipe implements PipeTransform {

  constructor( private domSanatizer: DomSanitizer) { } 

  transform(url: string): SafeResourceUrl {
    let urlCliente = `${url}`;
    return this.domSanatizer.bypassSecurityTrustResourceUrl( urlCliente );

  }
}
