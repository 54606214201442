import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
export class SaveErrorLogService {
    /**
     * Constructor del servicio
     */
    constructor() {
        /**
         * El uso de **SaveErrorLog.consoleLogDefined** ayudará en caso que se creen multiples instancias de SaveErrorLog, no se vuelva a modificar
         * la estructura del console.log, cosa que se hace en las siguientes líneas de código
         */
        // if(!SaveErrorLogService.consoleLogDefined) {
        //     SaveErrorLogService.consoleLogDefined = true;
        /** Tipo del error */
        this.TipoError = "";
        this.textDebugg = [];
        if (environment.production) {
            console.log = () => { };
            console.error = () => { };
            console.warn = () => { };
        }
        // /** Servicio de la console */
        const log = console.log.bind(console);
        const error = console.error.bind(console);
        const warn = console.warn.bind(console);
        // console.log = (arg: unknown, ...args: any[]) => {
        //     this.add("log", arg, ...args);
        //     log(arg, ...args);
        // }
        // console.error = (arg: unknown, ...args: any[]) => {
        //     this.add("error", arg, ...args);
        //     error(arg, ...args);
        // }
        // console.warn = (arg: unknown, ...args: any[]) => {
        //     this.add("warn", arg, ...args);
        //     warn(arg, ...args);
        // }
        // }
    }
    on(type, callback) {
        SaveErrorLogService.callbacks.push({
            type: type,
            callback: callback,
            id: ++SaveErrorLogService.idHandler
        });
        return SaveErrorLogService.idHandler;
    }
    /**
     * Eliminar un registro event emiter
     */
    removeListenner(id) {
        SaveErrorLogService.callbacks = SaveErrorLogService.callbacks.filter(c => c.id !== id);
    }
    emit(type, ...args) {
        SaveErrorLogService.callbacks.forEach(callback => {
            if (callback.type === type) {
                callback.callback(...args);
            }
        });
    }
    /**
     * Permite agregar un nuevo registro log
     * @param textLog texto del registro
     */
    add(type, arg, ...args) {
        args.unshift(arg);
        let logs = [];
        for (let textLog of args) {
            let resultLog;
            try {
                /** Verificar si se trata ya de un error */
                if (textLog instanceof Error) {
                    type = "error";
                    resultLog = (textLog.stack || ("ERROR: " + textLog.name + " >>>> " + textLog.message));
                }
                /** Verificar si se trata de un objeto */
                else if (textLog instanceof Object) {
                    resultLog = JSON.stringify(textLog, null, '    ');
                }
                else {
                    throw new Error("Not modified result");
                }
            }
            catch (err) {
                resultLog = String(textLog);
            }
            logs.push(resultLog);
        }
        /** Agregar log */
        const logRow = logs.join(" ");
        SaveErrorLogService.logs.push({
            type: type,
            text: logRow
        });
        this.emit("add", logRow, type);
        return SaveErrorLogService.logs.length;
    }
    /**
     * Vaciar todos los registros
     */
    clear() {
        SaveErrorLogService.logs = [];
    }
    /**
     * Obtener todos los logs
     */
    getAll() {
        /** TENER CUIDADO SE ESTÁ RETORNANDO UNA REFERENCIA Y NO UNA COPIA DE LOS REGISTROS */
        return SaveErrorLogService.logs;
    }
    /**
     * Retorna el texto que puede ser guardado en algún archivo.txt
     */
    save() {
        return SaveErrorLogService.logs.join("\n");
    }
}
/** Todos los logs compartidos */
SaveErrorLogService.logs = [];
/** Valor para saber si ya fué asignado un método del console.log */
SaveErrorLogService.consoleLogDefined = false;
/** Events */
SaveErrorLogService.callbacks = [];
/** id handler */
SaveErrorLogService.idHandler = 0;
SaveErrorLogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaveErrorLogService_Factory() { return new SaveErrorLogService(); }, token: SaveErrorLogService, providedIn: "root" });
