import * as tslib_1 from "tslib";
import { ToastController } from '@ionic/angular';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class NetworkStatusService {
    constructor(toastController) {
        this.toastController = toastController;
        this.status = true;
        fromEvent(window, 'online').pipe(debounceTime(100)).subscribe((event) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("online");
            const toast = yield this.toastController.create({
                message: 'Estas conectado a internet.',
                showCloseButton: true,
                closeButtonText: 'Cerrar',
                duration: 3000,
                color: 'success'
            });
            toast.present();
            this.status = true;
        }));
        fromEvent(window, 'offline').pipe(debounceTime(100)).subscribe((event) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("offline");
            const toast = yield this.toastController.create({
                message: 'No estas conectado a internet.',
                showCloseButton: true,
                closeButtonText: 'Cerrar',
                duration: 3000,
                color: 'danger'
            });
            toast.present();
            this.status = false;
            console.log({ 'Nuevo estado de': this.status });
        }));
    }
    getStatus() {
        return this.status;
    }
}
NetworkStatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NetworkStatusService_Factory() { return new NetworkStatusService(i0.ɵɵinject(i1.ToastController)); }, token: NetworkStatusService, providedIn: "root" });
