import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class SubscriberService {
    constructor() {
        this.currentSubscriber$ = new EventEmitter(false);
    }
    insertTo(values, table) {
        const headers = {
            none: 'for now'
        };
        // axios.post("",values,{ headers })
    }
}
SubscriberService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriberService_Factory() { return new SubscriberService(); }, token: SubscriberService, providedIn: "root" });
