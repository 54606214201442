import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LogErrorsService } from './log-errors.service';
import { SeguridadDatos } from './bscript.service';
import axios from 'axios';
import * as i0 from "@angular/core";
import * as i1 from "./bscript.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./log-errors.service";
export class MiEquipoService {
    constructor(security, _http, _logs) {
        this.security = security;
        this._http = _http;
        this._logs = _logs;
        this.speedData = [];
    }
    getImage(name) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'TokenAuthPlataform': environment.TokenBackendFull,
                        'Authorization': 'Basic ' + btoa(environment.UserBackendFull + ':' + environment.PasswordBackendFull),
                        'db': this.security.encrypt(environment.NameBD),
                        'table': this.security.encrypt('olt_onu_type'),
                        'type': this.security.encrypt('find-all-info'),
                        'campo': this.security.encrypt('name'),
                        'valor': this.security.encrypt(name)
                    })
                };
                const response = yield this._http.get(`${environment.ApiBackendThomas}`, httpOptions).toPromise();
                return {
                    data: response,
                    error: false,
                };
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    error: true,
                    data: []
                };
            }
        });
    }
    getSpeedData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const responseRow = yield this._http.get(`${environment.apiOltFirst}get_speed_profiles`).toPromise();
                const { response, } = JSON.parse(responseRow[0]);
                this.speedData = response;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                this.speedData = [];
            }
        });
    }
    getSpeed(name) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.speedData.length === 0) {
                    yield this.getSpeedData();
                }
                const dataObj = this.speedData.find((item) => item.name == name);
                const speed = this.transformKbToMb(dataObj.speed);
                return {
                    error: false,
                    data: speed,
                };
            }
            catch (error) {
                return {
                    error: true,
                    data: null
                };
            }
        });
    }
    transformKbToMb(speed) {
        if (speed < 1024) {
            return `${speed}mb`;
        }
        const transformed = Math.round(speed / 1024);
        return `${transformed}mb`;
    }
    getInfo(parameters) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield axios.get(`${environment.apiOlt}${parameters}`);
                // const response: any = await this._http.get(`${environment.apiOlt}${parameters}`).toPromise();
                // return JSON.parse(response[0])
                return JSON.parse(res.data[0]);
            }
            catch (error) {
                console.log(error);
                this._logs.insertarError(error.message);
                throw new Error("algo salio mal");
            }
        });
    }
    getAllInfo(deviceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const parameters = [
                //De aqui sacas la señal:
                `get_onu_signal/${deviceId}`,
                //de aqui sacas la cuenta:
                `get_onu_administrative_status/${deviceId}`,
                //Plan sácalo de aquí:
                `get_onu_speed_profiles/${deviceId}`,
                `get_onu_status/${deviceId}`
            ];
            try {
                const responses = yield Promise.all(parameters.map((params) => this.getInfo(params)));
                return responses;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    error: true,
                    data: []
                };
            }
        });
    }
}
MiEquipoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MiEquipoService_Factory() { return new MiEquipoService(i0.ɵɵinject(i1.SeguridadDatos), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.LogErrorsService)); }, token: MiEquipoService, providedIn: "root" });
