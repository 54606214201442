import { Injectable } from "@angular/core";
import { SaveErrorLogService } from "./CapturarRegistrarError";
// import { Share } from "@capacitor/share";
// import {
//     FileSharer
//   } from '@byteowls/capacitor-filesharer';

@Injectable({
    providedIn: 'root'
})
export class ConsoleMobileClass {

    /** 
     * Modal actualmente mostrado 
     * */
    public static ModalDisplayed: {
        EL_BACKGROUND: HTMLDivElement,
        EL_MODAL: HTMLDivElement,
        EL_TITLE: HTMLDivElement,
        EL_SHARE: HTMLButtonElement,
        EL_BODY: HTMLDivElement,
        idEmitter: number
    } | null = null;

    /**
     * Valor intervariable
     */
    private tColor: boolean = false;

    /**
     * countClicks
     */
    private countClicks: number = 0;

    /**
     * timer para inicializar
     */
    private timerToOpen: any;

    /**
     * Los mensajes del log
     */
    public allMessages: string[] = []

    private canShow = {
        error: true,
        warn: true,
        log: true,
    }
    
    // private countLogErrors: number = 0;
    // private showLogErrors: boolean = false;

    /** Logs guardados */
    // private static rowsLogs: string[] = [];

    constructor(private logService: SaveErrorLogService) {
        document.body.addEventListener("keypress", (ev: KeyboardEvent) => {

            if(ev.shiftKey && ev.key.toUpperCase() == "G") {
                this.IncrementTrysToOpen();
            }
        })
        document.body.addEventListener("keydown", (ev: KeyboardEvent) => {

            if(ev.shiftKey && ev.key.toUpperCase() == "G") {
                this.timerToOpen = setTimeout(() => {
                    this.Show("Logs de sistema");
                }, 2000);
            }
        })
        document.body.addEventListener("keyup", (ev: KeyboardEvent) => {

            if(ev.shiftKey && ev.key.toUpperCase() == "G") {
                if(this.timerToOpen) {
                    clearTimeout(this.timerToOpen);
                    this.timerToOpen = undefined;
                }
            }
        })
    }

    public IncrementTrysToOpen() {
        this.countClicks++;

        if(this.countClicks >= 10) {
            this.countClicks = 0;

            if(ConsoleMobileClass.ModalDisplayed) {
                this.Close();
            }
            else {
                this.Show("Logs de sistema");
            }
        }
    }

    /**
     * Agregar row log
     * @param text texto
     */
    private AddRowLog(text: string, type: "warn" | "log" | "error") {
      if(ConsoleMobileClass.ModalDisplayed) {
            this.allMessages.push(text)
            const EL_ROW = document.createElement("DIV");
            EL_ROW.style.padding = "5px";
            EL_ROW.style.margin = "10px 0px";
            EL_ROW.style.whiteSpace = "pre-wrap";
            EL_ROW.innerHTML = text;
            EL_ROW.setAttribute("data-type", type);

            switch(type) {
                case "warn":
                    EL_ROW.style.background = "rgba(255, 200, 100)";
                    break;

                case "error":
                    EL_ROW.style.background = "rgb(255 227 227)";
                    break;

                case "log":
                    EL_ROW.style.background = (this.tColor = !this.tColor) ? "rgb(227, 234, 255)" : "rgb(211 247 255)";
                    break;
            }
            
            // /**
            //  * removes all the logs when reach 100 elements (to optimize)
            //  */
            // if (ConsoleMobileClass.ModalDisplayed.EL_BODY.childNodes?.length > 100) {
            //     ConsoleMobileClass.ModalDisplayed.EL_BODY.childNodes.forEach((child, i) => {
            //         ConsoleMobileClass.ModalDisplayed.EL_BODY.removeChild(child)
            //     })
            // }
            ConsoleMobileClass.ModalDisplayed.EL_BODY.appendChild(EL_ROW);
            ConsoleMobileClass.ModalDisplayed.EL_BODY.scroll({top:ConsoleMobileClass.ModalDisplayed.EL_BODY.scrollHeight,left:0,behavior:'smooth'})
        }
    }

    /**
     * Permite mostrar el modal de la consola
     * @param title título del modal
     * @param subtitle subtítulo del modal
     */
    public Show(title: string, subtitle?: string) {
        if(!ConsoleMobileClass.ModalDisplayed) {
            /** Crear elementos */
            const EL_BACKGROUND = document.createElement("DIV") as HTMLDivElement;
            const EL_MODAL = document.createElement("DIV") as HTMLDivElement;
            const EL_TITLE = document.createElement("DIV") as HTMLDivElement;
            const EL_SHARE = document.createElement("BUTTON") as HTMLButtonElement;
            const EL_BODY = document.createElement("DIV") as HTMLDivElement;
            const EL_CLOSE = document.createElement("IMG") as HTMLImageElement;
            const EL_ROWTAGS = document.createElement("div") as HTMLDivElement;
            const EL_TAGTEXT = document.createElement("button") as HTMLButtonElement;
            const EL_TAGWARN = document.createElement("button") as HTMLButtonElement;
            const EL_TAGERROR = document.createElement("button") as HTMLButtonElement;

            const LoadRows = () => {
                // Eliminar elementos
                const toDelete: ChildNode[] = []
                ConsoleMobileClass.ModalDisplayed.EL_BODY.childNodes.forEach(child => {
                    toDelete.push(child);
                });
                toDelete.forEach(deleteItem => {
                    deleteItem.remove();
                })
                
                /**
                 * Preparar el cuerpo
                 */
                let data = this.logService.getAll().filter(item => {
                    switch(item.type) {
                        case "error":
                            return this.canShow.error;

                        case "log":
                            return this.canShow.log;

                        case "warn":
                            return this.canShow.warn;
                    }
                    return false;
                });
                let min = data.length - 400;
                
                data.slice(min < 0 ? 0 : min, data.length).forEach(textLog => {
                    /**
                     * Row de logs
                     */
                    this.AddRowLog(textLog.text, textLog.type);
                });
            }


            const idEmitter = this.logService.on("add", (textRow, type) => {
                this.AddRowLog(textRow, type);
            });

            /** Crear elementos */
            ConsoleMobileClass.ModalDisplayed = {
                EL_BACKGROUND: EL_BACKGROUND,
                EL_MODAL: EL_MODAL,
                EL_TITLE: EL_TITLE,
                EL_SHARE: EL_SHARE,
                EL_BODY: EL_BODY,
                idEmitter
            };

            
            /**
             * Preparar etiquetas
            */
            EL_ROWTAGS.style.display = "flex";
            EL_ROWTAGS.style.gap = "5px";
            EL_ROWTAGS.style.flexWrap = "wrap";
            EL_ROWTAGS.style.fontSize = "10px";
            EL_ROWTAGS.style.padding = "10px 0px";
           
            EL_TAGTEXT.innerHTML = "Log";
            EL_TAGWARN.innerHTML = "Warning";
            EL_TAGERROR.innerHTML = "Error";

            EL_TAGTEXT.style.backgroundColor = "dodgerblue";
            EL_TAGERROR.style.backgroundColor = "darkred";
            EL_TAGWARN.style.backgroundColor = "orangered";

            EL_TAGTEXT.style.color =    EL_TAGERROR.style.color =   EL_TAGWARN.style.color = "white";
            EL_TAGTEXT.style.border =   EL_TAGERROR.style.border =  EL_TAGWARN.style.border = "none";
            EL_TAGTEXT.style.padding =  EL_TAGERROR.style.padding = EL_TAGWARN.style.padding = "5px 10px";
            EL_TAGTEXT.style.flex =     EL_TAGERROR.style.flex =    EL_TAGWARN.style.flex = "1 100px";
            EL_TAGTEXT.style.borderRadius =  EL_TAGERROR.style.borderRadius = EL_TAGWARN.style.borderRadius = "20px";

            EL_TAGTEXT.style.opacity = this.canShow.log ? "1" : "0.3";
            EL_TAGERROR.style.opacity = this.canShow.error ? "1" : "0.3";
            EL_TAGWARN.style.opacity = this.canShow.warn ? "1" : "0.3";

            EL_TAGTEXT.onclick = (ev) => {
                EL_TAGTEXT.style.opacity = (this.canShow.log = !this.canShow.log) ? "1" : "0.3";
                LoadRows();
            }
            EL_TAGERROR.onclick = (ev) => {
                EL_TAGERROR.style.opacity = (this.canShow.error = !this.canShow.error) ? "1" : "0.3";
                LoadRows();
            }
            EL_TAGWARN.onclick = (ev) => {
                EL_TAGWARN.style.opacity = (this.canShow.warn = !this.canShow.warn) ? "1" : "0.3";
                LoadRows();
            }


            /**
             * Preparar el background
             */
            EL_BACKGROUND.style.background = "rgba(0,0,0,0.15)";
            EL_BACKGROUND.style.display = "flex";
            EL_BACKGROUND.style.alignItems = "center";
            EL_BACKGROUND.style.justifyContent = "center";
            EL_BACKGROUND.style.position = "fixed";
            EL_BACKGROUND.style.top = "0";
            EL_BACKGROUND.style.left = "0";
            EL_BACKGROUND.style.width = "100%";
            EL_BACKGROUND.style.height = "100%";
            EL_BACKGROUND.style.zIndex = "100000000000000000000000";

            /**
             * Preparar el modal
             */
            EL_MODAL.style.background = "white";
            EL_MODAL.style.padding = "8px";
            // EL_MODAL.style.maxWidth = "800px";
            EL_MODAL.style.width = "100vw";
            EL_MODAL.style.height = "100vh";
            EL_MODAL.style.display = "flex";
            EL_MODAL.style.flexDirection = "column";

            /**
             * Preparar el título
             */
            EL_TITLE.style.fontSize = "1.5em";
            EL_TITLE.style.fontWeight = "bold";
            EL_TITLE.style.display = "flex";
            EL_TITLE.style.flexDirection = "row";
            EL_TITLE.style.justifyContent = "space-between";
            EL_TITLE.style.userSelect = "none"
            EL_TITLE.style.paddingTop = "2rem";
            EL_TITLE.innerHTML = title;

            /**
             * Preparar el título
             */

            EL_SHARE.style.position = "fixed"
            EL_SHARE.style.bottom = "15px"
            EL_SHARE.style.right = "15px"
            EL_SHARE.style.fontSize = "30px"
            EL_SHARE.style.borderRadius = "50%"
            EL_SHARE.style.padding = "7px"
            EL_SHARE.style.width = "45px"
            EL_SHARE.style.height = "45px"
            EL_SHARE.style.background = "#4982ae"
            EL_SHARE.style.color = "white"
            EL_SHARE.innerHTML = `
              <ion-icon name="share-social"></ion-icon>
            `;
            EL_SHARE.addEventListener("click", (ev) => {
              // this.shareWithCapacitor('console_logs.txt',new File(this.allMessages, "console_logs.txt", { type: "text/plain",}));
              this.shareNative()
            })

            EL_BODY.style.overflow = "auto";

            EL_CLOSE.src = "/assets/close_icon.png";
            EL_CLOSE.style.width = "20px";
            EL_CLOSE.style.height = "20px";
            EL_CLOSE.style.cursor = "pointer";
            EL_CLOSE.addEventListener("click", (ev) => {
                this.Close();
            })

            document.body.appendChild(EL_BACKGROUND);
            EL_BACKGROUND.appendChild(EL_MODAL);
            EL_ROWTAGS.appendChild(EL_TAGERROR);
            EL_ROWTAGS.appendChild(EL_TAGTEXT);
            EL_ROWTAGS.appendChild(EL_TAGWARN);
            EL_MODAL.appendChild(EL_TITLE);
            EL_MODAL.appendChild(EL_ROWTAGS);
            EL_MODAL.appendChild(EL_SHARE);
            EL_TITLE.appendChild(EL_CLOSE);
            EL_MODAL.appendChild(EL_BODY);

            LoadRows();
        }

    }

    /**
     * Cerrar el modal
     */
    public Close(): void {
        if(ConsoleMobileClass.ModalDisplayed) {
            ConsoleMobileClass.ModalDisplayed.EL_TITLE.remove();
            ConsoleMobileClass.ModalDisplayed.EL_BODY.remove();
            ConsoleMobileClass.ModalDisplayed.EL_MODAL.remove();
            ConsoleMobileClass.ModalDisplayed.EL_BACKGROUND.remove();
            this.logService.removeListenner(ConsoleMobileClass.ModalDisplayed.idEmitter);

            /** Esto eliminará el modal de la memoria */
            ConsoleMobileClass.ModalDisplayed = null;
        }
    }

    // public handleLogErrorCount = () => {
    //   this.countLogErrors++;
    //   if (this.countLogErrors >= 10) {
    //     this.countLogErrors = 0
    //     this.showLogErrors = !this.showLogErrors
    //     // Remove all child elements
    //     while (ConsoleMobileClass?.ModalDisplayed?.EL_BODY?.firstChild) {
    //       ConsoleMobileClass?.ModalDisplayed?.EL_BODY?.removeChild(ConsoleMobileClass?.ModalDisplayed?.EL_BODY?.firstChild);
    //     }
    //     this.allMessages.forEach(log => this.AddRowLog(log))
    //   }
    // }

  //* To share the logs in other apps
  // public shareWithCapacitor = (fileName:string,base64Data:string | Blob): Promise<ShareResult> => {
    // return Filesystem.writeFile({
    //   path: fileName,
    //   data: base64Data,
    //   directory: Directory.Cache
    // })
    //   .then(() => {
    //     return Filesystem.getUri({
    //       directory: Directory.Cache,
    //       path: fileName
    //     });
    //   })
    //   .then((uriResult) => {
    //     return Share.share({
    //       title: fileName,
    //       text: fileName,
    //       url: uriResult.uri,
    //     });
    //   });
  // }

  public shareNative = (): void => {
    var navigator: any = (window as any).navigator;

    if ("share" in navigator) {
        navigator.share({files: [
          new File(this.allMessages, "console_logs.txt", { type: "text/plain",})
        ]
      })
    }
    else {
        // Share.canShare()
        //     .then((r) => {
        //         if(r.value) {
        //             FileSharer.share({
        //                 filename: "console_logs.txt",
        //                 base64Data: btoa(this.allMessages.join("\n")),
        //                 contentType: "text/plain"
        //             })
        //             .then(() => {})
        //             .catch(() => {})
        //         }
        //         else {
        //             throw new Error("No se puede compartir el registro");
        //         }
        //     })
        //     .catch((err) => {
        //         alert("No es posible compartir los registros desde este dispositivo");
        //     })
    }
  }
}
