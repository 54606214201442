import * as tslib_1 from "tslib";
import axios from "axios";
import { environment } from '../../environments/environment';
import { LocalStorageService } from "./local-storage.service";
import { ClubFibexFeedingService } from "./club-fibex-feeding.service";
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
import * as i2 from "./club-fibex-feeding.service";
export class QRService {
    constructor(_localStorageService, clubServie) {
        this._localStorageService = _localStorageService;
        this.clubServie = clubServie;
        this.rootAPI = environment.apiQR;
        this.rexpresionURL = /^https?:\/\/qr\.fibextelecom\.info\/[^\s\/?#]+(?:\?[^\s#]*)?$/;
        this.tokenStorageName = "tknQR";
    }
    generateTokens() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const { identidad } = this._localStorageService.get("cedulas")[0];
                console.log(identidad);
                const Data = { cedula: identidad };
                const responseAPi = yield axios.post(`${this.rootAPI}/loginAuth`, Data);
                const data = responseAPi.data;
                const { tknlg, refreshTkn } = data;
                console.log({ tknlg, refreshTkn });
                this._localStorageService.set(this.tokenStorageName, { tknlg, refreshTkn });
            }
            catch (error) {
                console.log("Error en obtener la respuesta por favor intenta mas tarde");
                console.log(error);
            }
        });
    }
    logOutToken() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const tokens = this._localStorageService.get(this.tokenStorageName);
            try {
                const headers = {
                    refresh: `Bearer ${tokens.refreshTkn}`
                };
                const responseAPi = yield axios.get(`${this.rootAPI}/logOutToken`, { headers });
                const data = responseAPi.data;
                console.log(data);
                this._localStorageService.remove(this.tokenStorageName);
                return Promise.resolve(data);
            }
            catch (error) {
                console.log("Error en obtener la respuesta por favor intenta mas tarde");
                console.log(error);
                return Promise.reject(error);
            }
        });
    }
    redirectCoupon(qrContent, codeCupon) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //EVALUAMOS EL QR 
            try {
                const respEXprReg = this.rexpresionURL.test(qrContent);
                // if(respEXprReg){
                const objectTokens = this._localStorageService.get(this.tokenStorageName);
                console.log(objectTokens);
                const headers = {
                    "Access-Control-Allow-Origin": "*",
                    "tknlg": `Bearer ${objectTokens.tknlg}`,
                    "refresh": `Bearer ${objectTokens.refreshTkn}`
                };
                const responseCoupon = yield this.clubServie.getCouponByID(codeCupon);
                const urlResponse = responseCoupon.QR ? `${qrContent}?codeQR=${responseCoupon.QR}` : qrContent;
                console.log(urlResponse);
                console.log(codeCupon);
                const responseGet = yield axios.get(`${urlResponse}`, { headers });
                const dataResponse = responseGet.data;
                //actualizar los tokens
                console.log(responseGet.headers);
                console.log(dataResponse);
                if (dataResponse.hasOwnProperty("error") && dataResponse.hasOwnProperty("status") && !dataResponse.status)
                    return Promise.reject(dataResponse);
                else if (dataResponse.hasOwnProperty("error"))
                    return Promise.reject(dataResponse.error);
                return Promise.resolve({ status: true, message: "Se ha realizado correctamente el procedimiento de la lectura del QR", data: dataResponse.data });
                // }
                // return Promise.resolve({status: false,message: "No cumple con la expresion regular del URL"})
            }
            catch (error) {
                if (error.hasOwnProperty("code") && error.code === 3000) {
                    const responseData = yield this.generateTokens();
                }
                return Promise.reject({ error });
            }
        });
    }
}
QRService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QRService_Factory() { return new QRService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.ClubFibexFeedingService)); }, token: QRService, providedIn: "root" });
